import * as React from 'react';
import styled from 'styled-components';
import { mobileSize } from '../../util/variables';
import { Modal, Wrapper, AppText } from '../ui';
import { AnimatedLinkAnchor } from '../ui/AnimatedLinkAnchor';
import { Company } from '../PageSegments/companies/OurCompanies';
import theme from '../../util/theme';
import { RichText } from '../../services';

interface CompanyPortfolioModalProps {
  showModal: boolean;
  toggleModal: () => void;
  data: Company;
}

const CompanyPortfolioModal: React.FunctionComponent<CompanyPortfolioModalProps> = ({
  showModal = false,
  toggleModal,
  data,
}) => {
  return (
    <Modal
      height="fit-content"
      width="fit-content"
      showModal={showModal}
      toggleModal={toggleModal}
      label="Company Portfolio"
    >
      <CloseModal onClick={toggleModal}>
        <Cross id="cross" />
      </CloseModal>
      <PortfolioContainer>
        <Wrapper>
          <PortfolioInnerContainer>
            <PortfolioDiv>
              <PortfolioSideBar>
                <PortfolioSideBarWrapper>
                  <PortfolioSiderbarCover>
                    <PortfolioProfileImage src={data.logo_url} />
                  </PortfolioSiderbarCover>
                  <ProfileSideContentContainer>
                    <FlexContainer>
                      <ProfileSideBarContentItemContainer>
                        <ProfileSideBarTitle>Headquarters</ProfileSideBarTitle>
                        <ProfileSideBarText>{data.headquarters}</ProfileSideBarText>
                      </ProfileSideBarContentItemContainer>
                      <ProfileSideBarContentItemContainer>
                        <ProfileSideBarTitle>Founded</ProfileSideBarTitle>
                        <ProfileSideBarText>{data.founded_date}</ProfileSideBarText>
                      </ProfileSideBarContentItemContainer>
                      <ProfileSideBarContentItemContainer>
                        <ProfileSideBarTitle>Invested</ProfileSideBarTitle>
                        <ProfileSideBarText>{data.invested_date}</ProfileSideBarText>
                      </ProfileSideBarContentItemContainer>

                      <ProfileSideBarContentItemContainer>
                        <ProfileSideBarTitle>BUSINESS MODEL</ProfileSideBarTitle>
                        <ProfileSideBarText>
                          {`${data?.business_model || 'N/A'}`}
                        </ProfileSideBarText>
                      </ProfileSideBarContentItemContainer>

                      <ProfileSideBarContentItemContainer>
                        <ProfileSideBarTitle>CUSTOMER</ProfileSideBarTitle>
                        <ProfileSideBarText>{`${data?.customer || 'N/A'}`}</ProfileSideBarText>
                      </ProfileSideBarContentItemContainer>

                      <ProfileSideBarContentItemContainer>
                        <ProfileSideBarTitle>GEO FOCUS</ProfileSideBarTitle>
                        {data?.geo_focus?.map?.(geo => {
                          return <ProfileSideBarText>{geo?.text || 'N/A'}</ProfileSideBarText>;
                        })}
                      </ProfileSideBarContentItemContainer>
                      <ProfileSideBarContentItemContainer>
                        <ProfileSideBarTitle>TREND</ProfileSideBarTitle>
                        {data?.industry_text?.map?.(industry => {
                          return <ProfileSideBarText>{industry?.text || 'N/A'}</ProfileSideBarText>;
                        })}
                      </ProfileSideBarContentItemContainer>
                    </FlexContainer>
                  </ProfileSideContentContainer>
                </PortfolioSideBarWrapper>
              </PortfolioSideBar>
              <PortfolioContent>
                <PortfolioBio>
                  {Boolean(RichText.asText(data.summary_text)) ? (
                    <TitleDiv>{RichText.render(data.summary_text)}</TitleDiv>
                  ) : (
                    <TitleDiv>
                      <b>{data.name}</b> is automating <b>{data.primary_trend_string}</b> for the{' '}
                      <b>{data.primary_industry_string} industry</b>
                    </TitleDiv>
                  )}
                  <HiddenTillMobile>
                    <ProfileSideBarContentItemContainer>
                      <ProfileSideBarTitle>Headquarters</ProfileSideBarTitle>
                      <ProfileSideBarText>{data.headquarters}</ProfileSideBarText>
                    </ProfileSideBarContentItemContainer>
                    <ProfileSideBarContentItemContainer>
                      <ProfileSideBarTitle>Founded</ProfileSideBarTitle>
                      <ProfileSideBarText>{data.founded_date}</ProfileSideBarText>
                    </ProfileSideBarContentItemContainer>
                    <ProfileSideBarContentItemContainer>
                      <ProfileSideBarTitle>Invested</ProfileSideBarTitle>
                      <ProfileSideBarText>{data.invested_date}</ProfileSideBarText>
                    </ProfileSideBarContentItemContainer>

                    <ProfileSideBarContentItemContainer>
                      <ProfileSideBarTitle>BUSINESS MODEL</ProfileSideBarTitle>
                      <ProfileSideBarText>{`${data?.business_model || 'N/A'}`}</ProfileSideBarText>
                    </ProfileSideBarContentItemContainer>

                    <ProfileSideBarContentItemContainer>
                      <ProfileSideBarTitle>CUSTOMER</ProfileSideBarTitle>
                      <ProfileSideBarText>{`${data?.customer || 'N/A'}`}</ProfileSideBarText>
                    </ProfileSideBarContentItemContainer>

                    <ProfileSideBarContentItemContainer>
                      <ProfileSideBarTitle>GEO FOCUS</ProfileSideBarTitle>
                      {data?.geo_focus?.map?.(geo => {
                        return <ProfileSideBarText>{geo?.text || 'N/A'}</ProfileSideBarText>;
                      })}
                    </ProfileSideBarContentItemContainer>
                    <ProfileSideBarContentItemContainer>
                      <ProfileSideBarTitle>TREND</ProfileSideBarTitle>
                      {data?.industry_text?.map?.(industry => {
                        return <ProfileSideBarText>{industry?.text || 'N/A'}</ProfileSideBarText>;
                      })}
                    </ProfileSideBarContentItemContainer>
                  </HiddenTillMobile>
                  <ProfileSideBarTitle>About</ProfileSideBarTitle>
                  <PortfolioBioText>{data.about}</PortfolioBioText>
                  <PortfolioBioText>
                    <AnimatedLinkAnchor fontSize="20px" color="black" to={data.website}>
                      Visit website
                    </AnimatedLinkAnchor>
                  </PortfolioBioText>
                </PortfolioBio>
              </PortfolioContent>
            </PortfolioDiv>
          </PortfolioInnerContainer>
        </Wrapper>
      </PortfolioContainer>
    </Modal>
  );
};

const HiddenTillMobile = styled.div`
  display: none;
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 22px;
  }
`;

const FlexContainer = styled.div`
  @media ${mobileSize} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 80hw;
  }
`;

const TitleDiv = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-size: 36px;
  font-weight: 300;
  letter-spacing: -0.75px;
  line-height: 50px;
  margin-bottom: 35px;
  @media ${mobileSize} {
    font-size: 30px;
    line-height: 33.75px;
  }
`;

const ProfileSideBarTitle = styled.div`
  font-family: ${theme.SECONDARY_FONT};
  margin-top: 0;
  margin-bottom: 7px;
  font-weight: 400;
  color: rgba(26, 26, 26, 0.6);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.16667;
  letter-spacing: 1px;
`;

const ProfileSideBarText = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-weight: 300;
  margin-bottom: 5px;
  font-size: 20px;
  @media ${mobileSize} {
    font-size: 16px;
    line-height: 18px;
  }
`;

const ProfileSideBarContentItemContainer = styled.div`
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: 0;
`;

const ProfileSideContentContainer = styled.div`
  width: calc(75% - 52px);
  float: left;
  margin-left: calc(25% - 38px + 60px);
  ${ProfileSideBarContentItemContainer}+${ProfileSideBarContentItemContainer} {
    margin-top: 24px;
  }
`;

const PortfolioContainer = styled.div`
  position: absolute;
  top: 25rem;
  left: 50%;
  width: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  @media ${mobileSize} {
    top: 0;
  }
`;

const PortfolioInnerContainer = styled.div`
  width: calc(100% - 3.75rem);
  float: left;
  margin-left: 1.875rem;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  background-color: ${theme.WHITE_COLOR};
  @media ${mobileSize} {
    width: auto;
    margin-left: 0;
    padding: 1.25rem 1.25rem 5rem;
  }
`;

const PortfolioDiv = styled.div`
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  width: calc(100% + 3.75rem);
  text-align: left;
  @media ${mobileSize} {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    width: calc(100% + 2.5rem);
  }
`;

const PortfolioSideBar = styled.div`
  position: relative;
  width: calc(33.33333% - 2.5rem);
  float: left;
  margin-left: 1.875rem;
  min-height: 0.0625rem;
  @media ${mobileSize} {
    display: none;
    width: calc(100% - 2.5rem);
    float: left;
    margin-left: 1.25rem;
  }
`;

const PortfolioSideBarWrapper = styled.div`
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  width: calc(100% + 3.75rem);
  @media ${mobileSize} {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    width: calc(100% + 2.5rem);
  }
`;

const PortfolioSiderbarCover = styled.div`
  position: relative;
  width: calc(75% - 3.28125rem);
  float: left;
  margin-left: 50px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  @media ${mobileSize} {
    top: 0;
    width: calc(33.33333% - 1.66667rem);
    float: left;
    margin-left: 1.25rem;
    -webkit-transform: none;
    transform: none;
  }
`;

const PortfolioProfileImage = styled.img`
  display: block;
  width: 100%;
  max-height: 200px;
  object-fit: contain;
  margin: auto;
  padding-top: 50px;
  @media ${mobileSize} {
    height: calc(33.33333vw - 1.66667rem);
  }
`;

const PortfolioContent = styled.div`
  width: calc(58.33333% - 2.96875rem);
  float: left;
  margin-left: 1.875rem;
  /* height: 100vh; */
  @media ${mobileSize} {
    width: calc(100% - 2.5rem);
    float: left;
    margin-left: 1.25rem;
    margin-top: 2.5rem;
  }
`;

const PortfolioBioText = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-size: 20px;
  line-height: 32px;
  font-weight: 300;
  margin-bottom: 42px;
  @media ${mobileSize} {
    font-family: ${theme.PRIMARY_FONT};
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 30px;
  }
`;

const PortfolioBio = styled.div`
  margin-top: 3.5625rem;
  font-size: 2.25rem;
  @media ${mobileSize} {
    font-size: 1.825rem;
    margin-top: 1.8125rem;
    a {
      font-size: 18px;
    }
  }
`;

const CloseModal = styled.div`
  cursor: pointer;
  position: fixed;
  top: 1.875rem;
  right: 1.875rem;
  z-index: 7;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: ${theme.WHITE_COLOR};
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  :hover {
    #cross {
      opacity: 1;
    }
  }
  @media ${mobileSize} {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 50px;
    width: 50px;
    border: 1px solid rgba(26, 26, 26, 0.5);
  }
`;

const Cross = styled.span`
  opacity: 0.2;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  :before {
    transform: rotate(45deg) translate(-50%, -50%);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1.5px;
    width: 22px;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    background-color: ${theme.BLACK_COLOR};
  }
  :after {
    transform: rotate(-45deg) translate(-50%, -50%);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1.5px;
    width: 22px;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    background-color: ${theme.BLACK_COLOR};
  }
  @media ${mobileSize} {
    opacity: 0.6;
    height: 1.25rem;
    width: 1.25rem;
    :before {
      height: 1px;
      width: 16px;
    }
    :after {
      height: 1px;
      width: 16px;
    }
  }
`;

export { CompanyPortfolioModal };
